import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/documentation.js";
import SEO from '../../components/seo';
import { Button } from 'godspeed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Button" description="hello description" mdxType="SEO" />
    <h1></h1>
    <h1>{`Button`}</h1>
    <h2>{`Buttons allow the user to execute events, actions or to navigate.`}</h2>
    <h3>{`Common use places`}</h3>
    <ul>
      <li parentName="ul">{`Forms`}</li>
      <li parentName="ul">{`Cards`}</li>
      <li parentName="ul">{`Modals`}</li>
      <li parentName="ul">{`Dialogs`}</li>
    </ul>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from 'godspeed\`
`}</code></pre>
    <br />
    <h1>{`Default Buttons`}</h1>
    <h2>{`These come out of the box, dark with white text.`}</h2>
    <p>{`The "text" prop is required on all button instances.`}</p>
    <p>{`You can pass the "bg" prop to change the background of the button.`}</p>
    <p>{`You can pass the "color" prop to change the font color of the button.`}</p>
    <main>
  <section>
    <Button text="Button" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" bg="steelblue" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" bg="steelblue" color="black" mdxType="Button" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Button" />
<Button text="Button" bg="steelblue" />
<Button text="Button" bg="steelblue" color="black" />
`}</code></pre>
    <br />
    <h1>{`Customized Buttons`}</h1>
    <p>{`You can pass the "to" prop to create a link button.`}</p>
    <main>
  <section>
    <Button text="Button" to="#" mdxType="Button" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Button" to="#" /> 
`}</code></pre>
    <br />
    <p>{`You can pass the "clear" prop to create a clear button.`}</p>
    <p>{`You can pass the "bg" prop to change the border color.`}</p>
    <p>{`You can pass the "color" prop to change the font color.`}</p>
    <main>
  <section>
    <Button text="Button" clear mdxType="Button" />
  </section>
  <section>
    <Button text="Button" clear bg="steelblue" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" clear color="steelblue" mdxType="Button" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Button" clear />
<Button text="Button" clear bg="steelblue" />
<Button text="Button" clear color="steelblue" />
`}</code></pre>
    <br />
    <p>{`You can pass the "shadow" prop to add shadow to the button.`}</p>
    <p>{`You can pass the "disabled" prop to disable the button.`}</p>
    <main>
  <section>
    <Button text="Button" shadow mdxType="Button" />
  </section>
  <section>
    <Button text="Button" disabled={true} mdxType="Button" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Button" shadow />
<Button text="Button" disabled={true} />
`}</code></pre>
    <br />
    <h1>{`Sizes`}</h1>
    <p>{`You can pass the "size" prop to change the font size.`}</p>
    <p>{`Default: "sm" (1rem: 16px)`}</p>
    <main>
  <section>
    <Button text="Button" size="xsm" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" size="sm" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" size="md" mdxType="Button" />
  </section>
  <section>
    <Button text="Button" size="lg" mdxType="Button" />
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button text="Button" size="xsm" />
<Button text="Button" size="sm" />
<Button text="Button" size="md" />
<Button text="Button" size="lg" />
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      